"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateFile = exports.ContractAndAnnexValidation = exports.ContractAndAnnexCorrectionValidation = exports.ContractAndAnnexModalValidation = void 0;
const validates_1 = require("core/helpers/validates");
const helper_1 = require("src/modules/common/helper");
const ContractAndAnnexModalValidation = (values) => {
    const formErrors = {
        text: (0, validates_1.required)(values.text)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.ContractAndAnnexModalValidation = ContractAndAnnexModalValidation;
const ContractAndAnnexCorrectionValidation = (values) => {
    const formErrors = {
        contractCorrectionFileUid: (0, validates_1.required)(values.contractCorrection.id)
    };
    const startDate = values.startDate ? (0, helper_1.transformParamsDate)(values.startDate) : null;
    const endDate = values.endDate ? (0, helper_1.transformParamsDate)(values.endDate) : null;
    if (startDate && endDate && startDate >= endDate) {
        formErrors.startDate = 'დაწყების თარიღი უნდა იყოს დასრულების თარიღზე ადრე';
        formErrors.endDate = 'დასრულების თარიღი უნდა იყოს დაწყების თარიღის შემდეგ';
    }
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.ContractAndAnnexCorrectionValidation = ContractAndAnnexCorrectionValidation;
const ContractAndAnnexValidation = (values) => {
    const formErrors = {
        startDate: (0, validates_1.required)(values.startDate),
        endDate: (0, validates_1.required)(values.endDate),
        contractError: !values.contract.id && !values.annex.id ? 'სავადებულოა აიტვირთოს ხელშეკრულება ან დანართი' : undefined,
        annexError: !values.contract.id && !values.annex.id ? 'სავადებულოა აიტვირთოს ხელშეკრულება ან დანართი' : undefined
    };
    const startDate = values.startDate ? (0, helper_1.transformParamsDate)(values.startDate) : null;
    const endDate = values.endDate ? (0, helper_1.transformParamsDate)(values.endDate) : null;
    if (startDate && endDate && startDate >= endDate) {
        formErrors.startDate = 'დაწყების თარიღი უნდა იყოს დასრულების თარიღზე ადრე';
        formErrors.endDate = 'დასრულების თარიღი უნდა იყოს დაწყების თარიღის შემდეგ';
    }
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.ContractAndAnnexValidation = ContractAndAnnexValidation;
const validateFile = (fileName) => /^.*\.(docx|DOCX)$/.test(fileName);
exports.validateFile = validateFile;
