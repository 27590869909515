"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseGuaranteeArticlesForSelect = exports.parseGuaranteeArticle = exports.parseGuaranteeArticles = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseGuaranteeArticles = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const guaranteeArticles = response.content;
    try {
        return {
            data: guaranteeArticles.data.map(el => {
                return {
                    id: el.id,
                    title: el.attributes.title,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(guaranteeArticles.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseGuaranteeArticles = parseGuaranteeArticles;
const parseGuaranteeArticle = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const guaranteeArticle = response.content;
    try {
        return {
            data: {
                id: guaranteeArticle.data.id,
                title: guaranteeArticle.data.attributes.title
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseGuaranteeArticle = parseGuaranteeArticle;
const parseGuaranteeArticlesForSelect = (response) => {
    if (response.content === null)
        return [];
    const guaranteeArticle = response.content;
    try {
        return guaranteeArticle.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.title
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseGuaranteeArticlesForSelect = parseGuaranteeArticlesForSelect;
