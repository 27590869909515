"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const react_1 = require("react");
const useCalculateBudgetArticles = (props) => {
    const [groupedItems, setGroupedItems] = (0, react_1.useState)([]);
    const [summery, setSummery] = (0, react_1.useState)(0);
    const [error, setError] = (0, react_1.useState)('');
    const [loadingItems, setLoadingItems] = (0, react_1.useState)(false);
    const Calculate = async (page = 1, data = []) => {
        setLoadingItems(true);
        const submissionVerifyItemsV2 = await index_1.SubmissionVerifiesApi.getSubmissionVerifyItemsV2(props.verifySubmissionId, { page });
        if (submissionVerifyItemsV2.data === null)
            return;
        const newData = data.concat(submissionVerifyItemsV2.data);
        if (page < submissionVerifyItemsV2.meta.pagination.totalPages) {
            return Calculate(page + 1, newData);
        }
        setLoadingItems(false);
        const newArray = newData.map(el => {
            const rate = !el.currencyExchangeRate ? 1 : el.currencyExchangeRate;
            if (!el.quantity || !el.price) {
                setError('გთხოვთ შეავსოთ გამოტოვებული ველები. აუცილებელია ველებია: ოდენობა, ერთეულის ფასი, ვალუტა და გაცვლითი კურსი');
            }
            return {
                id: el.budgetArticle?.id || 'missing',
                label: el.budgetArticle?.label || 'გამოტოვებული',
                sumPrice: !el.quantity || !el.price ? 0 : (el.quantity * rate * el.price) / (el.currency?.quantity || 1),
                count: 1
            };
        });
        let sum = 0;
        const groupedBudgetArticles = newArray.reduce((previousValue, currentValue) => {
            const item = previousValue.find(el => el.id === currentValue.id);
            if (item) {
                const newItem = {
                    ...item,
                    count: item.count + 1,
                    sumPrice: item.sumPrice + currentValue.sumPrice
                };
                const index = previousValue.findIndex(el => el.id === currentValue.id);
                previousValue[index] = newItem;
            }
            else {
                previousValue.push(currentValue);
            }
            sum = sum + (currentValue.id !== 'missing' ? currentValue.sumPrice : 0);
            return previousValue;
        }, []);
        setSummery(sum);
        setGroupedItems(groupedBudgetArticles);
    };
    (0, react_1.useEffect)(() => { Calculate().then(); }, []);
    return { groupedItems, summery, error, loadingItems };
};
exports.default = useCalculateBudgetArticles;
