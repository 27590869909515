"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rawMenuItems = void 0;
exports.rawMenuItems = [
    {
        name: 'მთავარი',
        url: '/dashboard',
        icon: 'fas fa-home',
        permission: 'public',
        children: []
    },
    {
        name: 'დოკუმენტები',
        url: '/documents',
        icon: 'fas fa-file-invoice',
        permission: 'cascade',
        children: [
            {
                name: 'შესყიდვები',
                url: '/documents/purchases',
                icon: 'fas fa-home',
                permission: 'cascade',
                children: [
                    {
                        name: 'პირობითი წარდგინება',
                        url: '/documents/purchases/conditional-submissions',
                        icon: 'fas fa-home',
                        permission: 'purchaseConditionalSubmissionsShowModule',
                        children: []
                    },
                    {
                        name: 'წარდგინება',
                        url: '/documents/purchases/submissions',
                        icon: 'fas fa-home',
                        permission: 'purchaseSubmissionsShowModule',
                        children: []
                    },
                    {
                        name: 'წარდგინების გადამოწმება',
                        url: '/documents/purchases/submission-verifies',
                        icon: 'fas fa-home',
                        permission: 'purchaseSubmissionVerifiesShowModule',
                        children: []
                    },
                    {
                        name: 'შესყიდვა/ხელშეკრულება',
                        url: '/documents/purchases/procurements',
                        icon: 'fas fa-home',
                        permission: 'procurementsShowModule',
                        children: []
                    },
                    {
                        name: 'მიღება-ჩაბარება / ინვოისი / ანგარიშფაქტურა',
                        url: '/documents/purchases/invoices',
                        icon: 'fas fa-home',
                        permission: 'invoicesShowModule',
                        children: []
                    }
                ]
            },
            {
                name: 'ადამიანური რესურსები',
                url: '/documents/human-resources',
                icon: 'fas fa-home',
                permission: 'cascade',
                children: [
                    {
                        name: 'დანიშვნის პირობითი წარდგინება',
                        url: '/documents/human-resources/assign-submissions',
                        icon: 'fas fa-home',
                        permission: 'assignSubmissionsShowModule',
                        children: []
                    },
                    {
                        name: 'დანიშვნის წარდგინების გადამოწმება',
                        url: '/documents/human-resources/assign-submission-verifies',
                        icon: 'fas fa-home',
                        permission: 'assignSubmissionVerifiesShowModule',
                        children: []
                    },
                    {
                        name: 'შვებულებები',
                        url: '/documents/human-resources/vacations',
                        icon: 'fas fa-home',
                        permission: 'vacationsShowModule',
                        children: []
                    },
                    {
                        name: 'შრომითი ხელშეკრულებები',
                        url: '/documents/human-resources/assign-contracts',
                        icon: 'fas fa-home',
                        permission: 'assignContractsShowModule',
                        children: []
                    }
                ]
            },
            {
                name: 'ადმინისტრაციული ბრძანებები',
                url: '/documents/administrative-orders',
                icon: 'fas fa-home',
                permission: 'administrativeOrdersShowModule',
                children: []
            }
        ]
    },
    {
        name: 'ანგარიშგებები',
        url: '/reports',
        icon: 'fas fa-chart-line',
        permission: 'cascade',
        children: [
            {
                name: 'შესყიდვები',
                url: '/reports/purchases',
                icon: 'fas fa-home',
                permission: 'cascade',
                children: [
                    {
                        name: 'სტატისტიკები',
                        url: '/reports/purchases/statistics',
                        icon: 'fas fa-home',
                        permission: 'procurementsReport',
                        children: []
                    }
                ]
            }
        ]
    },
    {
        name: 'ცნობარები',
        url: '/references',
        icon: 'fas fa-folder-open',
        permission: 'cascade',
        children: [
            {
                name: 'თანამშრომლები',
                url: '/references/employees',
                icon: 'fas fa-chart-line',
                permission: 'usersShowModule',
                children: []
            },
            {
                name: 'კომპანიები',
                url: '/references/companies',
                icon: 'fas fa-chart-line',
                permission: 'companiesShowModule',
                children: []
            },
            {
                name: 'ბანკები',
                url: '/references/banks',
                icon: 'fas fa-chart-line',
                permission: 'banksShowModule',
                children: []
            },
            {
                name: 'დასვენების დღეები',
                url: '/references/holidays',
                icon: 'fas fa-chart-line',
                permission: 'holidaysShowModule',
                children: []
            },
            {
                name: 'ვალუტები',
                url: '/references/currencies',
                icon: 'fas fa-chart-line',
                permission: 'currenciesShowModule',
                children: []
            },
            {
                name: 'კონტრაქტის ტიპები',
                url: '/references/contract-types',
                icon: 'fas fa-chart-line',
                permission: 'contractTypesShowModule',
                children: []
            },
            {
                name: 'სტრუქტურული ერთეულები',
                url: '/references/structural-units',
                icon: 'fas fa-chart-line',
                permission: 'structuralUnitsShowModule',
                children: []
            },
            {
                name: 'შესყიდვები',
                url: '/references/purchases',
                icon: 'fas fa-chart-line',
                permission: 'cascade',
                children: [
                    {
                        name: 'შესყიდვის საგნები',
                        url: '/references/purchases/purchase-subjects',
                        icon: 'fas fa-chart-line',
                        permission: 'inventoryConfigurationsShowModule',
                        children: []
                    },
                    {
                        name: 'მახასიათებლები',
                        url: '/references/purchases/purchase-specification',
                        icon: 'fas fa-chart-line',
                        permission: 'inventorySpecificationsShowModule',
                        children: []
                    },
                    {
                        name: 'საგნები',
                        url: '/references/purchases/purchase-items',
                        icon: 'fas fa-chart-line',
                        permission: 'inventoryItemsShowModule',
                        children: []
                    },
                    {
                        name: 'კატეგორიები',
                        url: '/references/purchases/categories',
                        icon: 'fas fa-chart-line',
                        permission: 'inventoryCategoriesShowModule',
                        children: []
                    },
                    {
                        name: 'საბუღალტრო ანგარიშები',
                        url: '/references/purchases/subject-accounting',
                        icon: 'fas fa-chart-line',
                        permission: 'inventoryCategoriesShowModule',
                        children: []
                    }
                ]
            },
            {
                name: 'საბიუჯეტო მუხლები',
                url: '/references/budget-articles',
                icon: 'fas fa-chart-line',
                permission: 'budgetArticlesShowModule',
                children: []
            },
            {
                name: 'მისამართები',
                url: '/references/addresses',
                icon: 'fas fa-chart-line',
                permission: 'cascade',
                children: [
                    {
                        name: 'ქვეყნები',
                        url: '/references/addresses/countries',
                        icon: 'fas fa-chart-line',
                        permission: 'countriesShowModule',
                        children: []
                    },
                    {
                        name: 'რეგიონები',
                        url: '/references/addresses/regions',
                        icon: 'fas fa-chart-line',
                        permission: 'regionsShowModule',
                        children: []
                    },
                    {
                        name: 'რაიონები',
                        url: '/references/addresses/districts',
                        icon: 'fas fa-chart-line',
                        permission: 'districtsShowModule',
                        children: []
                    },
                    {
                        name: 'ადგილმდებარეობები',
                        url: '/references/addresses/locations',
                        icon: 'fas fa-chart-line',
                        permission: 'locationsShowModule',
                        children: []
                    },
                    {
                        name: 'საუნივერსიტეტო კორპუსები',
                        url: '/references/addresses/administrative-buildings',
                        icon: 'fas fa-chart-line',
                        permission: 'administrativeBuildingsShowModule',
                        children: []
                    },
                    {
                        name: 'ოთახები',
                        url: '/references/addresses/rooms',
                        icon: 'fas fa-chart-line',
                        permission: 'roomsShowModule',
                        children: []
                    }
                ]
            },
            {
                name: 'დაფინანსებები',
                url: '/references/funding',
                icon: 'fas fa-chart-line',
                permission: 'cascade',
                children: [
                    {
                        name: 'დაფინანსების წყაროები',
                        url: '/references/funding/funding-sources',
                        icon: 'fas fa-chart-line',
                        permission: 'fundingSourcesShowModule',
                        children: []
                    },
                    {
                        name: 'ბიუჯეტის წყაროები',
                        url: '/references/funding/budget-sources',
                        icon: 'fas fa-chart-line',
                        permission: 'budgetSourcesShowModule',
                        children: []
                    },
                    {
                        name: 'პროექტები',
                        url: '/references/funding/projects',
                        icon: 'fas fa-chart-line',
                        permission: 'projectsShowModule',
                        children: []
                    },
                    {
                        name: 'დონორები',
                        url: '/references/funding/donors',
                        icon: 'fas fa-chart-line',
                        permission: 'donorsShowModule',
                        children: []
                    }
                ]
            },
            {
                name: 'ადამიანური რესურსები',
                url: '/references/human-resources',
                icon: 'fas fa-chart-line',
                permission: 'cascade',
                children: [
                    {
                        name: 'თანამდებობის ქვეტიპები',
                        url: '/references/human-resources/position-sub-types',
                        icon: 'fas fa-chart-line',
                        permission: 'positionSubTypeShowModule',
                        children: []
                    },
                    {
                        name: 'თანამდებობის ტიპები',
                        url: '/references/human-resources/position-types',
                        icon: 'fas fa-chart-line',
                        permission: 'positionTypesShowModule',
                        children: []
                    },
                    {
                        name: 'თანამდებობის ტიპების კონფიგურაცია',
                        url: '/references/human-resources/position-type-configs',
                        icon: 'fas fa-chart-line',
                        permission: 'positionTypesConfigShowModule',
                        children: []
                    },
                    {
                        name: 'შესასრულებელი სამუშაოს სახეები',
                        url: '/references/human-resources/work-types',
                        icon: 'fas fa-chart-line',
                        permission: 'workTypesShowModule',
                        children: []
                    },
                    {
                        name: 'შრომითი ხელშეკრულების ტიპები',
                        url: '/references/human-resources/contract-types',
                        icon: 'fas fa-chart-line',
                        permission: 'HRContractsShowModule',
                        children: []
                    },
                    {
                        name: 'სამუშაო მიმართულება',
                        url: '/references/human-resources/work-directions',
                        icon: 'fas fa-chart-line',
                        permission: 'workDirectionsShowModule',
                        children: []
                    }
                ]
            },
            {
                name: 'საგარანტიო ვადები',
                url: '/references/warranty-terms',
                icon: 'fas fa-chart-line',
                permission: 'warrantyTermsShowModule',
                children: []
            },
            {
                name: 'CPV კოდები',
                url: '/references/cpv-codes',
                icon: 'fas fa-chart-line',
                permission: 'cpvCodesShowModule',
                children: []
            },
            {
                name: 'განზომილების ერთეულები',
                url: '/references/dimension-units',
                icon: 'fas fa-chart-line',
                permission: 'dimensionUnitsShowModule',
                children: []
            },
            {
                name: 'შესყიდვის ტიპები',
                url: '/references/procurement-types',
                icon: 'fas fa-chart-line',
                permission: 'purchaseProcurementTypeRead',
                children: []
            },
            {
                name: 'იურიდიული საფუძვლები',
                url: '/references/legal-basics',
                icon: 'fas fa-chart-line',
                permission: 'legalBasicsShowModule',
                children: []
            },
            {
                name: 'შესყიდვის ობიექტის გარანტია',
                url: '/references/guarantee-articles',
                icon: 'fas fa-chart-line',
                permission: 'guaranteeArticlesShowModule',
                children: []
            },
            {
                name: 'საწყობები',
                url: '/references/warehouses',
                icon: 'fas fa-chart-line',
                permission: 'warehouseShowModule',
                children: []
            }
        ]
    },
    {
        name: 'ადმინისტრირება',
        url: '/administer',
        icon: 'fas fa-user',
        permission: 'cascade',
        children: [
            {
                name: 'მომხმარებლები',
                url: '/administer/users',
                icon: 'fas fa-chart-line',
                permission: 'usersShowModule',
                children: []
            },
            {
                name: 'როლები',
                url: '/administer/roles',
                icon: 'fas fa-chart-line',
                permission: 'rolesRead',
                children: []
            },
            {
                name: 'სიახლეები',
                url: '/administer/news',
                icon: 'fas fa-chart-line',
                permission: 'usersShowModule',
                children: []
            },
            {
                name: 'ფაილები',
                url: '/administer/files',
                icon: 'fas fa-chart-line',
                permission: 'mediaFilesShowModule',
                children: []
            },
            {
                name: 'მეილები',
                url: '/administer/sent-emails',
                icon: 'fas fa-chart-line',
                permission: 'emailsShowModule',
                children: []
            },
            {
                name: 'ავტორიზაციების ისტორია',
                url: '/administer/auth-histories',
                icon: 'fas fa-chart-line',
                permission: 'userAuthLogsShowModule',
                children: []
            }
        ]
    },
    {
        name: 'პირადი სივრცე',
        url: '/personal-documents',
        icon: 'fas fa-user',
        permission: 'cascade',
        children: [
            {
                name: 'ჩემი სტრუქტურა',
                url: '/personal-documents/my-structure',
                icon: 'fas fa-chart-line',
                permission: 'structuralUnitsShowModule',
                children: []
            },
            {
                name: 'ჩემი ფაილები',
                url: '/personal-documents/my-files',
                icon: 'fas fa-chart-line',
                permission: 'mediaFilesShowModule',
                children: []
            }
        ]
    }
];
