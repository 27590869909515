"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const guaranteeArticleAutoComplete_1 = require("src/modules/documents/purchases/procurements/store/guaranteeArticleAutoComplete");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const guaranteeArticles_1 = require("api/references/guaranteeArticles");
const react_1 = require("react");
const useGuaranteeArticleAutocomplete = () => {
    const [state, dispatch] = (0, guaranteeArticleAutoComplete_1.useGuaranteeArticleAutocompleteReducer)();
    const [guaranteeArticleId, setGuaranteeArticleId] = (0, react_1.useState)(null);
    const [guaranteeArticle, setGuaranteeArticle] = (0, react_1.useState)(null);
    const getGuaranteeArticleAutocomplete = async (params) => {
        dispatch({ type: 'SEND_GUARANTEE_ARTICLE_REQUEST' });
        const guaranteeArticleAutocomplete = await (0, guaranteeArticles_1.getGuaranteeArticlesForSelect)(params);
        dispatch({ type: 'DONE_GUARANTEE_ARTICLE_REQUEST', payload: { data: guaranteeArticleAutocomplete } });
    };
    const resetAutocompleteData = (event) => {
        if (!event)
            return dispatch({ type: 'RESET_DATA' });
        const isChosen = document.querySelector('#autocomplete-guaranteeArticle_listing')?.contains(event.target);
        if (!isChosen)
            dispatch({ type: 'RESET_DATA' });
    };
    const debouncedSearch = (value) => {
        const params = value ? ({ filters: { keyword: value } }) : {};
        (0, debouncedMethods_1.debouncedOnFunc)(params, getGuaranteeArticleAutocomplete);
    };
    return { state, resetAutocompleteData, debouncedSearch, setGuaranteeArticle, guaranteeArticle, guaranteeArticleId, setGuaranteeArticleId };
};
exports.default = useGuaranteeArticleAutocomplete;
