"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const guaranteeArticle_1 = require("src/modules/references/guaranteeArticle/store/guaranteeArticle");
const index_1 = require("api/index");
const helpers_1 = require("src/modules/references/guaranteeArticle/helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useGuaranteeArticle = (props) => {
    const [state, dispatch] = (0, guaranteeArticle_1.useGuaranteeArticleReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getGuaranteeArticle = async (id) => {
        dispatch({ type: 'SEND_GUARANTEE_ARTICLE_REQUEST' });
        const guaranteeArticle = await index_1.GuaranteeArticlesApi.getGuaranteeArticle(id);
        dispatch({ type: 'DONE_GUARANTEE_ARTICLE_REQUEST', payload: guaranteeArticle });
    };
    const createGuaranteeArticle = async (values, allowedToPush = true) => {
        const params = (0, helpers_1.transformGuaranteeArticleParams)(values);
        dispatch({ type: 'SEND_GUARANTEE_ARTICLE_CREATE' });
        const guaranteeArticle = await index_1.GuaranteeArticlesApi.createGuaranteeArticle(params);
        dispatch({ type: 'DONE_GUARANTEE_ARTICLE_CREATE', payload: guaranteeArticle });
        if (guaranteeArticle.data !== null) {
            if (allowedToPush)
                navigate(`/references/guarantee-articles/${guaranteeArticle.data.id}/edit`);
        }
        return guaranteeArticle;
    };
    const updateGuaranteeArticle = async (id, values) => {
        const params = (0, helpers_1.transformGuaranteeArticleParams)(values);
        dispatch({ type: 'SEND_GUARANTEE_ARTICLE_UPDATE' });
        const guaranteeArticle = await index_1.GuaranteeArticlesApi.updateGuaranteeArticle(id, params);
        dispatch({ type: 'DONE_GUARANTEE_ARTICLE_UPDATE', payload: guaranteeArticle });
        return guaranteeArticle;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getGuaranteeArticle(props.id).then(); }, [props.id]);
    return { state, updateGuaranteeArticle, getGuaranteeArticle, closeErrorMessage, createGuaranteeArticle };
};
exports.default = useGuaranteeArticle;
