"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const conditionalSubmission_1 = require("../store/conditionalSubmission");
const index_1 = require("api/index");
const react_1 = require("react");
const helpers_1 = require("../helpers");
const store_1 = require("core/store");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useConditionalSubmission = (props) => {
    const [state, dispatch] = (0, conditionalSubmission_1.useConditionalSubmissionReducer)();
    const [fetchStep, setFetchStep] = (0, react_1.useState)(0);
    const [structuralUnits, setStructuralUnits] = (0, react_1.useState)([]);
    const [showSweetAlert, setShowSweetAlert] = (0, react_1.useState)(false);
    const [showProgressInPurchaseAlert, setShowProgressInPurchaseAlert] = (0, react_1.useState)(false);
    const [dimensionUnits, setDimensionUnits] = (0, react_1.useState)([]);
    const [deletingFiles, setDeletingFiles] = (0, react_1.useState)([]);
    const [showDeleteFileAlert, setShowDeleteFileAlert] = (0, react_1.useState)(null);
    const [conditionalSubmissionFileType, setConditionalSubmissionFileType] = (0, react_1.useState)('');
    const [showDeclineMessage, setShowDeclineMessage] = (0, react_1.useState)(false);
    const [showSuspendMessage, setShowSuspendMessage] = (0, react_1.useState)(false);
    const [showSendButton, setShowSendButton] = (0, react_1.useState)(false);
    const [isExportingWord, setIsExportingWord] = (0, react_1.useState)(false);
    const [isExportingExcel, setIsExportingExcel] = (0, react_1.useState)(false);
    const [buttonNavigate, setButtonNavigate] = (0, react_1.useState)(conditionalSubmission_1.initialConditionalSubmissionNavigate);
    const [conditionalParamsIds, setConditionalParamsIds] = (0, react_1.useState)({ conditionalSubmissionIds: [], meta: store_1.initialMeta });
    const [listingParams, setListingParams] = (0, react_1.useState)('?');
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const getConditionalSubmission = async (id) => {
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_REQUEST' });
        const conditionalSubmission = await index_1.ConditionalSubmissionsApi.getConditionalSubmission(id);
        dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_REQUEST', payload: conditionalSubmission });
        return conditionalSubmission;
    };
    const getDeterminations = async (conditionalSubmissionId) => {
        dispatch({ type: 'SEND_DETERMINATIONS_REQUEST' });
        const determinations = await index_1.ConditionalSubmissionsApi.getDeterminations(conditionalSubmissionId);
        dispatch({ type: 'DONE_DETERMINATIONS_REQUEST', payload: determinations });
    };
    const getDimensionUnitsForSelect = async () => {
        const dimensionUnit = await index_1.DimensionUnitsApi.getDimensionUnitsForSelect({ perPage: 300 });
        setDimensionUnits(dimensionUnit);
    };
    const updateConditionalSubmission = async (id, values) => {
        const params = (0, helpers_1.transformConditionalSubmissionParams)(values);
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_UPDATE' });
        const conditionalSubmission = await index_1.ConditionalSubmissionsApi.updateConditionalSubmission(id, params);
        dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_UPDATE', payload: conditionalSubmission });
        return conditionalSubmission;
    };
    const updateDetermination = async (values) => {
        const params = (0, helpers_1.transformDeterminationParams)(values);
        dispatch({ type: 'SEND_DETERMINATION_UPDATE' });
        const determination = await index_1.ConditionalSubmissionsApi.updateDetermination(state.currentVersion.id, params);
        dispatch({ type: 'DONE_DETERMINATION_UPDATE', payload: determination });
        return determination;
    };
    const deleteDeterminationFile = async (id) => {
        setDeletingFiles(prev => [...prev, id]);
        const determinationFile = await index_1.ConditionalSubmissionsApi.deleteDeterminationFile(state.currentVersion.id, { fileUids: [id] });
        setDeletingFiles(prev => prev.filter(el => el !== id));
        return { errors: determinationFile.errors, status: determinationFile.status };
    };
    const suspendDeterminationItem = async (suspendComment) => {
        setShowSuspendMessage(false);
        dispatch({ type: 'SEND_DETERMINATION_SUSPEND_REQUEST' });
        const determination = await index_1.ConditionalSubmissionsApi.suspendDeterminationItem(state.currentVersion.id, { comment: suspendComment });
        dispatch({ type: 'DONE_DETERMINATION_SUSPEND_REQUEST', payload: determination });
        return determination;
    };
    const declineDeterminationItem = async (declineComment) => {
        setShowDeclineMessage(false);
        dispatch({ type: 'SEND_DETERMINATION_DECLINE_REQUEST' });
        const determination = await index_1.ConditionalSubmissionsApi.declineDeterminationItem(state.currentVersion.id, { comment: declineComment });
        dispatch({ type: 'DONE_DETERMINATION_DECLINE_REQUEST', payload: determination });
        return determination;
    };
    const sendProgressInPurchase = async (id) => {
        dispatch({ type: 'SEND_PROGRESS_IN_PURCHASE_REQUEST' });
        const conditionalSubmission = await index_1.ConditionalSubmissionsApi.sendProgressInPurchase(id);
        dispatch({ type: 'DONE_PROGRESS_IN_PURCHASE_REQUEST', payload: conditionalSubmission });
        return conditionalSubmission;
    };
    const deleteConditionalSubmissionFile = async (id) => {
        setDeletingFiles(prev => [...prev, id]);
        const conditionalSubmissionFile = await index_1.ConditionalSubmissionsApi.deleteConditionalSubmissionFile(props.conditionalSubmissionId, { fileUids: [id] });
        setDeletingFiles(prev => prev.filter(el => el !== id));
        return { errors: conditionalSubmissionFile.errors, status: conditionalSubmissionFile.status };
    };
    const createConditionalSubmission = async (values) => {
        const params = (0, helpers_1.transformConditionalSubmissionParams)(values);
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_CREATE' });
        const conditionalSubmission = await index_1.ConditionalSubmissionsApi.createConditionalSubmission(params);
        dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_CREATE', payload: conditionalSubmission });
        if (conditionalSubmission.data !== null) {
            getLastFirstElementStatus(conditionalSubmission.data.id);
            navigate(`/documents/purchases/conditional-submissions/${conditionalSubmission.data.id}/edit`);
        }
        return conditionalSubmission;
    };
    const declineItem = async (declineComment) => {
        setShowDeclineMessage(false);
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_DECLINE_REQUEST' });
        const conditionalSubmission = await index_1.ConditionalSubmissionsApi.declineItem(props.conditionalSubmissionId, { comment: declineComment });
        dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_DECLINE_REQUEST', payload: conditionalSubmission });
        return conditionalSubmission;
    };
    const createDetermination = () => navigate(`/documents/purchases/conditional-submissions/${props.conditionalSubmissionId}/determination/create`, { replace: true });
    const suspendItem = async (suspendComment) => {
        setShowSuspendMessage(false);
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_SUSPEND_REQUEST' });
        const conditionalSubmission = await index_1.ConditionalSubmissionsApi.suspendItem(props.conditionalSubmissionId, { comment: suspendComment });
        dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_SUSPEND_REQUEST', payload: conditionalSubmission });
        return conditionalSubmission;
    };
    const setStructuralUnit = (value) => {
        if (value === null)
            return setStructuralUnits([]);
        setStructuralUnits(value.structuralUnits);
    };
    const sendConditionalSubmission = async (id) => {
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_SEND_REQUEST' });
        const conditionalSubmission = await index_1.ConditionalSubmissionsApi.sendToChancellery(id);
        dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_SEND_REQUEST', payload: conditionalSubmission });
        return conditionalSubmission;
    };
    const sendDetermination = async (id) => {
        dispatch({ type: 'SEND_DETERMINATION_SEND_REQUEST' });
        const determination = await index_1.ConditionalSubmissionsApi.sendDeterminationToChancellery(id);
        dispatch({ type: 'DONE_DETERMINATION_SEND_REQUEST', payload: determination });
        return determination;
    };
    const sendDeterminationProgressInPurchase = async (id) => {
        dispatch({ type: 'SEND_DETERMINATION_PROGRESS_IN_PURCHASE_REQUEST' });
        const determination = await index_1.ConditionalSubmissionsApi.sendDeterminationProgressInPurchase(id);
        dispatch({ type: 'DONE_DETERMINATION_PROGRESS_IN_PURCHASE_REQUEST', payload: determination });
        return determination;
    };
    const fileDownload = async (type, id, isOriginal) => {
        const setExporting = (value) => {
            if (type === 'word')
                setIsExportingWord(value);
            if (type === 'excel')
                setIsExportingExcel(value);
        };
        setExporting(true);
        const file = isOriginal
            ? await index_1.ConditionalSubmissionsApi.getConditionalSubmissionFiles({ type }, id)
            : await index_1.ConditionalSubmissionsApi.getDeterminationSubmissionFiles(id);
        if (file.data)
            open(file.data.link);
        setExporting(false);
        return file;
    };
    const loadOriginal = () => { dispatch({ type: 'LOAD_ORIGINAL_VERSION' }); };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const getConditionalSubmissionIDs = async (value) => {
        const onlyMine = localStorage.getItem('conditionalSubmissionOnlyMine') || undefined;
        const conditionalSubmissionsParams = (0, helpers_1.transformParamsToFilterParams)({ ...value, onlyMine });
        const itemsParams = (0, helpers_1.getConditionalSubmissionItemsParamsFromUrl)(location.search);
        const conditionalSubmissions = await index_1.ConditionalSubmissionsApi.getConditionalSubmissions(conditionalSubmissionsParams);
        if (conditionalSubmissions.data !== null && conditionalSubmissions.meta) {
            const responseIds = {
                conditionalSubmissionIds: conditionalSubmissions.data.map(el => el.id), meta: conditionalSubmissions.meta
            };
            const searchParams = (0, queryString_1.stringify)({ ...(0, helpers_1.transformFilterParamsToUrl)(value), ...itemsParams });
            setListingParams(searchParams || '?');
            setConditionalParamsIds(responseIds);
            return responseIds;
        }
        return null;
    };
    const nextConditionalSubmission = async () => {
        setButtonNavigate(prevState => ({ ...prevState, next: true, isLoading: true }));
        const currentIndex = conditionalParamsIds.conditionalSubmissionIds.indexOf(props.conditionalSubmissionId);
        const nextConditionalSubmission = conditionalParamsIds.conditionalSubmissionIds[currentIndex + 1];
        const { pagination } = conditionalParamsIds.meta;
        const params = (0, helpers_1.transformFilterParamsToUrl)((0, helpers_1.transformUrlToFilterParams)(location.search));
        if (nextConditionalSubmission) {
            const itemsParams = (0, helpers_1.getConditionalSubmissionItemsParamsFromUrl)(location.search);
            navigate('/documents/purchases/conditional-submissions/' + nextConditionalSubmission + '/edit' + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const lastElement = pagination.currentPage === pagination.totalPages && conditionalParamsIds.conditionalSubmissionIds.length === currentIndex + 2;
            setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === pagination.totalPages) {
                setButtonNavigate(prevState => ({ ...prevState, next: false, lastElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToFilterParams)(location.search);
            const response = await getConditionalSubmissionIDs({ ...formValues, page: pagination.currentPage + 1 });
            if (response && response.conditionalSubmissionIds[0]) {
                const itemsParams = (0, helpers_1.getConditionalSubmissionItemsParamsFromUrl)(location.search);
                formValues.page = response.meta.pagination.currentPage;
                const urlParams = { ...(0, helpers_1.transformFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage, ...itemsParams };
                navigate('/documents/purchases/conditional-submissions/' + response.conditionalSubmissionIds[0] + '/edit' + (0, queryString_1.stringify)(urlParams));
                const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.conditionalSubmissionIds.length === 1;
                setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
            }
        }
    };
    const previousConditionalSubmission = async () => {
        setButtonNavigate(prevState => ({ ...prevState, previous: true, isLoading: true }));
        const currentIndex = conditionalParamsIds.conditionalSubmissionIds.indexOf(props.conditionalSubmissionId);
        const prevConditional = conditionalParamsIds.conditionalSubmissionIds[currentIndex - 1];
        const { pagination } = conditionalParamsIds.meta;
        const params = (0, helpers_1.transformFilterParamsToUrl)((0, helpers_1.transformUrlToFilterParams)(location.search));
        if (prevConditional) {
            const itemsParams = (0, helpers_1.getConditionalSubmissionItemsParamsFromUrl)(location.search);
            navigate('/documents/purchases/conditional-submissions/' + prevConditional + '/edit' + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const firstElement = pagination.currentPage === 1 && currentIndex === 1;
            setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === 1) {
                setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToFilterParams)(location.search);
            const response = await getConditionalSubmissionIDs({ ...formValues, page: pagination.currentPage - 1 });
            if (response) {
                const previousConditional = response.conditionalSubmissionIds[response.conditionalSubmissionIds.length - 1];
                if (previousConditional) {
                    formValues.page = response.meta.pagination.currentPage;
                    const itemsParams = (0, helpers_1.getConditionalSubmissionItemsParamsFromUrl)(location.search);
                    const urlParams = { ...(0, helpers_1.transformFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage, ...itemsParams };
                    navigate('/documents/purchases/conditional-submissions/' + previousConditional + '/edit' + (0, queryString_1.stringify)(urlParams));
                    const firstElement = response.meta.pagination.currentPage === 1 && currentIndex === 1;
                    setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElements: false, firstElement, isLoading: false }));
                }
            }
        }
    };
    const getLastFirstElementStatus = (id) => {
        const params = (0, helpers_1.transformUrlToFilterParams)(location.search);
        params.onlyMine = localStorage.getItem('conditionalSubmissionOnlyMine') || undefined;
        getConditionalSubmissionIDs(params).then(response => {
            if (response) {
                const { currentPage, totalPages } = response.meta.pagination;
                const conditionalIndex = response.conditionalSubmissionIds.indexOf(id || props.conditionalSubmissionId);
                if (conditionalIndex === 0 && currentPage === 1)
                    setButtonNavigate(prevState => ({ ...prevState, firstElement: true }));
                if (currentPage === totalPages && conditionalIndex === response.conditionalSubmissionIds.length - 1) {
                    setButtonNavigate(prevState => ({ ...prevState, lastElement: true }));
                }
            }
        });
    };
    (0, react_1.useEffect)(() => {
        if (props.conditionalSubmissionId)
            getLastFirstElementStatus(props.conditionalSubmissionId);
    }, []);
    (0, react_1.useEffect)(() => {
        if (fetchStep >= 2)
            getDimensionUnitsForSelect().then(() => setFetchStep(3));
    }, [fetchStep >= 2]);
    (0, react_1.useEffect)(() => {
        if (props.conditionalSubmissionId) {
            getConditionalSubmission(props.conditionalSubmissionId).then(response => {
                setFetchStep(1);
                if (response.data?.hasDeterminations) {
                    getDeterminations(props.conditionalSubmissionId).then(() => setFetchStep(2));
                }
                else {
                    setFetchStep(2);
                }
            });
        }
    }, [props.conditionalSubmissionId]);
    return {
        state,
        setStructuralUnit,
        dimensionUnits,
        structuralUnits,
        getConditionalSubmission,
        fileDownload,
        showSweetAlert,
        sendConditionalSubmission,
        showProgressInPurchaseAlert,
        setShowProgressInPurchaseAlert,
        setShowSweetAlert,
        sendProgressInPurchase,
        updateConditionalSubmission,
        createConditionalSubmission,
        deleteConditionalSubmissionFile,
        deletingFiles,
        setShowDeleteFileAlert,
        showDeleteFileAlert,
        closeErrorMessage,
        conditionalSubmissionFileType,
        setConditionalSubmissionFileType,
        declineItem,
        showDeclineMessage,
        setShowDeclineMessage,
        showSuspendMessage,
        setShowSuspendMessage,
        suspendItem,
        createDetermination,
        loadOriginal,
        updateDetermination,
        sendDetermination,
        deleteDeterminationFile,
        suspendDeterminationItem,
        declineDeterminationItem,
        sendDeterminationProgressInPurchase,
        setShowSendButton,
        showSendButton,
        setFetchStep,
        fetchStep,
        isExportingExcel,
        buttonNavigate,
        isExportingWord,
        nextConditionalSubmission,
        previousConditionalSubmission,
        listingParams
    };
};
exports.default = useConditionalSubmission;
