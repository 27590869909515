"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.correctionReasons = exports.correctionType = exports.parseCorrectionCalculations = exports.parseUpdateLetterOrSubmission = exports.parseLetterOrSubmission = exports.parseProcurementCorrection = exports.parseUpdateProcurementCorrection = exports.parseCreateProcurementCorrection = exports.parseProcurementCorrections = void 0;
const types_1 = require("api/documents/purchases/procurementCorrections/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_2 = require("api/documents/purchases/procurements/types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parsers_2 = require("api/documents/purchases/procurements/parsers");
const parsers_3 = require("api/documents/purchases/submissions/parsers");
const parseProcurementCorrections = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const procurementCorrections = response.content;
    try {
        return {
            data: { versions: procurementCorrections.data.map(el => (0, exports.parseProcurementCorrection)(el)) },
            status: response.status,
            errors: response.errors
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementCorrections = parseProcurementCorrections;
const parseCreateProcurementCorrection = (response) => {
    if (response.content === null)
        return { data: null, status: response.status, errors: response.errors };
    try {
        return {
            data: (0, exports.parseProcurementCorrection)(response.content.data),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCreateProcurementCorrection = parseCreateProcurementCorrection;
const parseUpdateProcurementCorrection = (response) => {
    return { data: null, status: response.status, errors: response.errors };
};
exports.parseUpdateProcurementCorrection = parseUpdateProcurementCorrection;
const parseProcurementCorrection = (data) => {
    const status = data.relationships.correctionItem.data.attributes.status;
    const companyBankAccount = data.relationships.correctionItem.data.relationships.companyBankAccount?.data;
    const companyAddress = data.relationships.correctionItem.data.relationships.companyAddress?.data;
    const companySignatory = data.relationships.correctionItem.data.relationships.companySignatory?.data;
    const company = data.relationships.correctionItem.data.relationships.company.data;
    const structuralUnit = data.relationships.account?.data.relationships.contract.data.relationships.structuralUnit;
    const project = data.relationships.account?.data.relationships.contract.data.relationships.project;
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const submissionsWithoutItems = [];
    const submissionsWithItems = [];
    data.relationships.submissions.data.forEach(el => {
        if (el.relationships.submission)
            submissionsWithItems.push(el);
        else
            submissionsWithoutItems.push(el);
    });
    const parsedSubmissionsWithoutItems = parseSubmissionsWithoutItems(submissionsWithoutItems);
    const parsedSubmissionsWithItems = parseSubmissionsWithItems(submissionsWithItems);
    const todayInMs = new Date().setHours(0, 0, 0, 0);
    const contractValidityDateInMs = new Date(data.relationships.correctionItem.data.attributes.contractValidityDate).setHours(0, 0, 0, 0);
    return {
        letterOrSubmissionId: data.id,
        hasForceMajeureComment: data.attributes.hasForceMajeureComment,
        moduleType: data.relationships.correctionItem.data.type,
        id: data.relationships.correctionItem.data.id,
        disableCorrectionCreate: todayInMs > contractValidityDateInMs || !!data.relationships.correctionItem.data.attributes.actuallyDoneComment,
        correctionType: exports.correctionType.find(el => el.id === String(data.attributes.type)),
        letterNumber: data.attributes.letterNumber || undefined,
        letterDate: (0, parsers_1.nullableTransformDate)(data.attributes.letterDate) || undefined,
        submissionNumber: data.attributes.submissionNumber || undefined,
        remainingItemsQuantity: data.relationships.correctionItem.data.attributes.remainingItemsQuantity,
        submissionRequestRegistration: (0, parsers_1.nullableTransformDate)(data.attributes.submissionRequestRegistration),
        submissionDeadline: (0, parsers_1.nullableTransformDate)(data.attributes.submissionDeadline),
        submissionExecution: (0, parsers_1.nullableTransformDate)(data.attributes.submissionExecution),
        createdAt: (0, parsers_1.transformDate)(data.attributes.createdAt),
        updatedAt: (0, parsers_1.transformDate)(data.attributes.updatedAt),
        number: data.relationships.correctionItem.data.attributes.number,
        signedDocumentNumber: data.relationships.correctionItem.data.attributes.signedDocumentNumber,
        dateOfConclusion: (0, parsers_1.nullableTransformDate)(data.relationships.correctionItem.data.attributes.dateOfConclusion),
        deliveryDate: (0, parsers_1.transformDate)(data.relationships.correctionItem.data.attributes.deliveryDate),
        contractValidityDate: (0, parsers_1.transformDate)(data.relationships.correctionItem.data.attributes.contractValidityDate),
        advanceGuaranteeValidityPeriod: (0, parsers_1.nullableTransformDate)(data.relationships.correctionItem.data.attributes.advanceGuaranteeValidityPeriod),
        contractGuaranteeValidityPeriod: (0, parsers_1.nullableTransformDate)(data.relationships.correctionItem.data.attributes.contractGuaranteeValidityPeriod),
        goodsGuaranteeValidityPeriod: (0, parsers_1.nullableTransformDate)(data.relationships.correctionItem.data.attributes.goodsGuaranteeValidityPeriod),
        templateType: data.relationships.correctionItem.data.attributes.templateType,
        declineMessage: data.relationships.correctionItem.data.attributes.declineMessage,
        actuallyDoneComment: data.relationships.correctionItem.data.attributes.actuallyDoneComment,
        sumPrice: data.relationships.correctionItem.data.attributes.sumPrice,
        tenderLink: data.relationships.correctionItem.data.attributes.tenderLink || '',
        cmr: data.relationships.correctionItem.data.attributes.cmr || '',
        spa: data.relationships.correctionItem.data.attributes.spa || '',
        con: data.relationships.correctionItem.data.attributes.con || '',
        isOther: data.relationships.correctionItem.data.relationships.mediaFiles?.data.find(file => file.attributes.pivots.type === types_2.TemplateTypes.ELECTRONIC
            || file.attributes.pivots.type === types_2.TemplateTypes.SIMPLIFIED
            || file.attributes.pivots.type === types_2.TemplateTypes.CONSOLIDATED)?.attributes.pivots.is_other === apiGlobalTypes_1.YesNoStatus.YES,
        procurementVisualStatus: (0, parsers_2.parseProcurementVisualStatus)(data.relationships.correctionItem.data.attributes.status),
        subVisualStatus: (0, parsers_2.parseProcurementSubStatus)(data.relationships.correctionItem.data.attributes.subStatus),
        subStatus: data.relationships.correctionItem.data.attributes.subStatus,
        creator: {
            id: data.relationships.correctionItem.data.relationships.creator.data.relationships.user.data.id,
            accountId: data.relationships.correctionItem.data.relationships.creator.data.id,
            fullname: data.relationships.correctionItem.data.relationships.creator.data.relationships.user.data.attributes.fullname
        },
        // items: data.relationships.correctionItem.data.relationships.items.data.map(item => parseProcurementItem(item, date)),
        submissionAuthor: data.attributes.type === types_1.ProcurementCorrectionType.SUBMISSION ? {
            id: data.relationships.account?.data.relationships.user?.data.id,
            label: data.relationships.account?.data.relationships.user?.data.attributes.fullname
        } : null,
        submissionAuthorPosition: data.attributes.type === types_1.ProcurementCorrectionType.SUBMISSION ? {
            id: structuralUnit?.data.id || '',
            accountId: data.relationships.account?.data.id,
            label: structuralUnit ? structuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
        } : null,
        reasons: data.relationships.reasons.data.map(el => exports.correctionReasons.find(reason => reason.id === String(el.attributes.reason))),
        files: (0, parsers_2.parseProcurementFiles)(data.relationships.correctionItem.data.relationships.mediaFiles?.data)
            .filter(el => el.type === types_2.TemplateTypes.ADDITIONAL_FILES || el.type === null),
        attachedFiles: (0, parsers_2.parseProcurementFiles)(data.relationships.correctionItem.data.relationships.mediaFiles?.data)
            .filter(el => el.type === types_2.TemplateTypes.SIGNATURE_DOCUMENT),
        attachedSignedFiles: (0, parsers_2.parseProcurementFiles)(data.relationships.correctionItem.data.relationships.mediaFiles?.data)
            .filter(el => el.type === types_2.TemplateTypes.SIGNED),
        letterOrSubmissionFiles: data.relationships.mediaFiles.data.map(file => (0, parsers_1.parseFile)(file)),
        procurementFile: (0, parsers_2.parseProcurementFiles)(data.relationships.correctionItem.data.relationships.mediaFiles?.data).find(el => el.type === types_2.TemplateTypes.ELECTRONIC || el.type === types_2.TemplateTypes.SIMPLIFIED || el.type === types_2.TemplateTypes.CONSOLIDATED) || null,
        approvers: data.relationships.correctionItem.data.relationships.approvers.data?.map(approver => (0, parsers_2.parseProcurementApprover)(approver)) || [],
        type: {
            id: data.relationships.correctionItem.data.relationships.type.data.id,
            label: data.relationships.correctionItem.data.relationships.type.data.attributes.name
        },
        revertComment: data.relationships.correctionItem.data.attributes.revertComment,
        company: {
            id: company.id,
            label: company.attributes.name,
            bankAccounts: company.relationships.bankAccounts.data.map(bankAccount => {
                return {
                    id: bankAccount.id,
                    label: bankAccount.attributes.accountNumber,
                    disabled: !bankAccount.attributes.status
                };
            }),
            addresses: company.relationships.addresses.data.map(address => ({ id: address.id, label: address.attributes.address, disabled: !address.attributes.isActive })),
            signatories: company.relationships.signatories.data.map(signatory => ({
                id: signatory.id,
                label: signatory.attributes.firstname + ' ' + signatory.attributes.lastname,
                phones: signatory.relationships.phones?.data.map(phone => ({ id: phone.id, label: phone.attributes.phone })) || []
            }))
        },
        legalBasis: {
            id: data.attributes.legalBasisId,
            label: data.attributes.legalBasisId
        },
        guaranteeArticles: [],
        textFieldForLegalBasis: '',
        companyBankAccount: companyBankAccount && {
            id: companyBankAccount.id,
            label: companyBankAccount.attributes.accountNumber
        } || null,
        companyAddress: companyAddress && {
            id: companyAddress.id,
            label: companyAddress.attributes.address
        } || null,
        companySignatory: companySignatory && {
            id: companySignatory.id,
            label: companySignatory.attributes.firstname + ' ' + companySignatory.attributes.lastname
        } || null,
        status,
        lock: status !== types_2.ProcurementStatuses.DRAFT
            && status !== types_2.ProcurementStatuses.READY_FOR_CORRECTION
            && status !== types_2.ProcurementStatuses.APPROVAL_PROGRESS
            && status !== types_2.ProcurementStatuses.REVERTED
            && status !== types_2.ProcurementStatuses.SUSPENDED,
        original: false,
        submissions: [...parsedSubmissionsWithItems, ...parsedSubmissionsWithoutItems]
    };
};
exports.parseProcurementCorrection = parseProcurementCorrection;
const parseLetterOrSubmission = (response) => {
    if (response.content === null)
        return null;
    const updateLetterOrSubmission = response.content;
    try {
        const submissionsWithoutItems = [];
        const submissionsWithItems = [];
        updateLetterOrSubmission.data.relationships.submissions.data.forEach(el => {
            if (el.relationships.submission)
                submissionsWithItems.push(el);
            else
                submissionsWithoutItems.push(el);
        });
        const parsedSubmissionsWithoutItems = parseSubmissionsWithoutItems(submissionsWithoutItems);
        const parsedSubmissionsWithItems = parseSubmissionsWithItems(submissionsWithItems);
        return {
            submissionOrLetterType: Number(exports.correctionType.find(el => el.id === String(updateLetterOrSubmission.data.attributes.type)).id),
            letterOrSubmissionFiles: updateLetterOrSubmission.data.relationships.mediaFiles.data.map(file => (0, parsers_1.parseFile)(file)),
            letterOrSubmissionId: updateLetterOrSubmission.data.id,
            correctionType: exports.correctionType.find(el => el.id === String(updateLetterOrSubmission.data.attributes.type)),
            letterNumber: updateLetterOrSubmission.data.attributes.letterNumber || undefined,
            letterDate: (0, parsers_1.nullableTransformDate)(updateLetterOrSubmission.data.attributes.letterDate) || undefined,
            reasons: updateLetterOrSubmission.data.relationships.reasons.data.map(el => exports.correctionReasons.find(reason => reason.id === String(el.attributes.reason))),
            submissions: [...parsedSubmissionsWithItems, ...parsedSubmissionsWithoutItems]
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return null;
    }
};
exports.parseLetterOrSubmission = parseLetterOrSubmission;
const parseSubmissionsWithItems = (submissionWithItems) => {
    const submissions = [];
    submissionWithItems
        .filter(unit => !!unit.relationships.submission.data.relationships.account.data.relationships.contract.data.relationships.structuralUnit)
        .forEach(el => {
        const responseSubmission = el.relationships.submission;
        const submissionUnit = el.relationships.submission.data.relationships.account.data.relationships.contract.data.relationships.structuralUnit;
        const item = el.relationships.submissionItem && (0, parsers_3.parseSubmissionItemTypeForProcurementCorrection)(el.relationships.submissionItem.data);
        const existingSubmissionIndex = submissions.findIndex(item => item.submissionId === el.relationships.submission.data.id);
        if (existingSubmissionIndex === -1) {
            const submission = {
                id: el.relationships.submission.data.id,
                submissionId: responseSubmission.data.id,
                letterOrSubmissionFiles: responseSubmission.data.relationships.mediaFiles?.data.map(file => (0, parsers_1.parseFile)(file)),
                submissionAuthor: {
                    id: responseSubmission.data.relationships.account.data.relationships.user.data.id,
                    label: responseSubmission.data.relationships.account.data.relationships.user.data.attributes.fullname
                },
                submissionAuthorPosition: {
                    id: submissionUnit.data.id,
                    label: submissionUnit.data.attributes.fullName,
                    accountId: responseSubmission.data.relationships.account.data.id
                },
                submissionDeadline: (0, parsers_1.transformDate)(responseSubmission.data.attributes.expirationDate),
                submissionNumber: responseSubmission.data.attributes.number,
                submissionExecution: (0, parsers_1.transformDate)(responseSubmission.data.attributes.executionDate),
                submissionRequestRegistration: (0, parsers_1.transformDate)(responseSubmission.data.attributes.date),
                chosenItems: item ? [item] : [],
                selectAll: false
            };
            submissions.push(submission);
        }
        else {
            const submissionValue = submissions[existingSubmissionIndex];
            if (submissionValue && item) {
                submissions[existingSubmissionIndex] = {
                    ...submissionValue,
                    chosenItems: submissionValue?.chosenItems
                        ? [...submissionValue.chosenItems, item]
                        : [item]
                };
            }
        }
    });
    return submissions;
};
const parseSubmissionsWithoutItems = (submissionWithoutItems) => {
    return submissionWithoutItems.map(submissionWithoutItems => {
        const structuralUnit = submissionWithoutItems.relationships.account?.data.relationships.contract.data.relationships.structuralUnit;
        return {
            id: submissionWithoutItems.id,
            submissionId: null,
            letterOrSubmissionFiles: submissionWithoutItems.relationships.mediaFiles.data.map(file => (0, parsers_1.parseFile)(file)) || [],
            submissionAuthor: {
                id: submissionWithoutItems.relationships.account?.data.relationships.user.data.id,
                label: submissionWithoutItems.relationships.account?.data.relationships.user.data.attributes.fullname
            },
            submissionAuthorPosition: {
                id: structuralUnit.data.id,
                label: structuralUnit.data.attributes.fullName,
                accountId: submissionWithoutItems.relationships.account?.data.id
            },
            submissionDeadline: (0, parsers_1.nullableTransformDate)(submissionWithoutItems.attributes.submissionDeadline),
            submissionNumber: submissionWithoutItems.attributes.submissionNumber,
            submissionExecution: (0, parsers_1.nullableTransformDate)(submissionWithoutItems.attributes.submissionExecution),
            submissionRequestRegistration: (0, parsers_1.nullableTransformDate)(submissionWithoutItems.attributes.submissionRequestRegistration),
            selectAll: false,
            chosenSelectAll: true
        };
    });
};
const parseUpdateLetterOrSubmission = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const updateLetterOrSubmission = response.content;
    try {
        const submissionsWithoutItems = [];
        const submissionsWithItems = [];
        updateLetterOrSubmission.data.relationships.submissions.data.forEach(el => {
            if (el.relationships.submission)
                submissionsWithItems.push(el);
            else
                submissionsWithoutItems.push(el);
        });
        const parsedSubmissionsWithoutItems = parseSubmissionsWithoutItems(submissionsWithoutItems);
        const parsedSubmissionsWithItems = parseSubmissionsWithItems(submissionsWithItems);
        return {
            data: {
                submissionOrLetterType: Number(exports.correctionType.find(el => el.id === String(updateLetterOrSubmission.data.attributes.type)).id),
                letterOrSubmissionFiles: updateLetterOrSubmission.data.relationships.mediaFiles.data.map(file => (0, parsers_1.parseFile)(file)),
                letterOrSubmissionId: updateLetterOrSubmission.data.id,
                correctionType: exports.correctionType.find(el => el.id === String(updateLetterOrSubmission.data.attributes.type)),
                letterNumber: updateLetterOrSubmission.data.attributes.letterNumber || undefined,
                letterDate: (0, parsers_1.nullableTransformDate)(updateLetterOrSubmission.data.attributes.letterDate) || undefined,
                reasons: updateLetterOrSubmission.data.relationships.reasons.data.map(el => exports.correctionReasons.find(reason => reason.id === String(el.attributes.reason))),
                submissions: [...parsedSubmissionsWithItems, ...parsedSubmissionsWithoutItems]
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseUpdateLetterOrSubmission = parseUpdateLetterOrSubmission;
const parseCorrectionCalculations = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const verifySubmissionItems = response.content;
    try {
        const budgetArticles = [];
        const headerProcurements = [];
        verifySubmissionItems.data.forEach(item => {
            const sum = item.attributes.quantity * (0, parsers_1.exchangeToGEL)(item.attributes.price, item.relationships.verifyItem ? item.relationships.verifyItem.data.currency?.data : item.relationships.submissionItem?.data.relationships.currency?.data, item.attributes.currencyExchangeRate);
            const budgetArticle = {
                id: item.relationships.budgetArticle ? item.relationships.budgetArticle.data.id : null,
                label: item.relationships.budgetArticle ? item.relationships.budgetArticle.data.attributes.name : 'საბიუჯეტო მუხლის გარეშე',
                procurements: [{
                        id: item.relationships.documentWithOnlyNumber?.data.id,
                        label: item.relationships.documentWithOnlyNumber?.data.attributes.signedDocumentNumber || item.relationships.documentWithOnlyNumber?.data.attributes.number,
                        sum
                    }]
            };
            const procurement = {
                id: item.relationships.documentWithOnlyNumber?.data.id,
                label: item.relationships.documentWithOnlyNumber?.data.attributes.signedDocumentNumber || item.relationships.documentWithOnlyNumber?.data.attributes.number,
                sum
            };
            const headerProcurement = {
                id: item.relationships.documentWithOnlyNumber?.data.id,
                label: item.relationships.documentWithOnlyNumber?.data.attributes.signedDocumentNumber || item.relationships.documentWithOnlyNumber?.data.attributes.number,
                sum
            };
            // budgetArticles group
            const groupedBudgetArticle = budgetArticles.find(el => {
                if (item.relationships.budgetArticle === null)
                    return el.id === null;
                return el.id === item.relationships.budgetArticle.data.id;
            });
            if (!groupedBudgetArticle) {
                budgetArticles.push(budgetArticle);
            }
            else {
                const groupedProcurement = groupedBudgetArticle.procurements.find(el => el.id === item.relationships.documentWithOnlyNumber?.data.id);
                if (!groupedProcurement) {
                    groupedBudgetArticle.procurements.push(procurement);
                }
                else {
                    groupedProcurement.sum += sum;
                    const index = budgetArticle.procurements.indexOf(procurement);
                    budgetArticle.procurements.splice(index, 1, groupedProcurement);
                }
            }
            // procurement header group
            const groupedHeaderProcurements = headerProcurements.find(el => el.id === item.relationships.documentWithOnlyNumber?.data.id);
            if (!groupedHeaderProcurements) {
                headerProcurements.push(headerProcurement);
            }
            else {
                groupedHeaderProcurements.sum += sum;
                const index = headerProcurements.indexOf(headerProcurement);
                headerProcurements.splice(index, 1, groupedHeaderProcurements);
            }
        });
        return {
            data: {
                headerProcurements,
                budgetArticles
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCorrectionCalculations = parseCorrectionCalculations;
exports.correctionType = [
    { id: String(types_1.ProcurementCorrectionType.LETTER), label: 'წერილი' },
    { id: String(types_1.ProcurementCorrectionType.SUBMISSION), label: 'წარდგინება' }
];
exports.correctionReasons = [
    { id: String(types_1.ReasonTypes.DELIVERY_DATE), label: 'საქონლის მოწოდების ვადა' },
    { id: String(types_1.ReasonTypes.CONTRACT_VALIDITY_DATE), label: 'ხელშეკრულების მოქმედების ვადა' },
    { id: String(types_1.ReasonTypes.PRICE), label: 'ხელშეკრულების ღირებულების ცვლილება' },
    { id: String(types_1.ReasonTypes.COMPANY_REQUISITES), label: 'კომპანიის რეკვიზიტებში ცვლილება' },
    { id: String(types_1.ReasonTypes.BILATERAL_TERMINATION_OF_CONTRACT), label: 'ორმხრივად ხელშეკრულების შეწყვეტა' },
    { id: String(types_1.ReasonTypes.FUNDING_SOURCE), label: 'დაფინანსების წყაროს ცვლილება' },
    { id: String(types_1.ReasonTypes.CHANGE_CPV_CODE), label: 'CPV კოდების ცვლილება' },
    { id: String(types_1.ReasonTypes.SUBJECT_NAME), label: 'შესყიდვის საგნის დასახელება' },
    { id: String(types_1.ReasonTypes.UNILATERAL_TERMINATION_OF_CONTRACT), label: 'ცალმხრივად ხელშეკრულების შეწყვეტა' },
    { id: String(types_1.ReasonTypes.CHANGE_QUANTITY_OF_PURCHASE_SUBJECTS), label: 'შესყიდვის საგნის რაოდენობის ცვლილება' },
    { id: String(types_1.ReasonTypes.ADD_ADVANCE_GUARANTEE_ITEM), label: 'საავანსო გარანტიის პუნქტის დამატება' }
];
