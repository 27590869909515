"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customElements = void 0;
exports.customElements = '~tag:head-of-administration,' +
    '~tag:company-name,' +
    '~tag:company-address,' +
    '~tag:company-bank-accounts,' +
    '~tag:company-identification-number,' +
    '~tag:projects,' +
    '~tag:procurement-total-price-human-readable,' +
    '~tag:procurement-total-price,' +
    '~tag:head-cpv-code,' +
    '~tag:date-of-conclusion,' +
    '~tag:company-director-name,' +
    '~tag:company-director-position,' +
    '~tag:date-month-in-spin,' +
    'tag:items-data-company,' +
    'tag:items-data,' +
    '~tag:validate-date,' +
    '~tag:delivery-date,' +
    '~tag:document-number,' +
    '~tag:letter-or-submission,' +
    '~tag:item-name,' +
    '~tag:author-info,' +
    'tag:items-data-on-change-reason,' +
    '~tag:signed-document-number,' +
    '~tag:corrected-signed-document-number,' +
    'tag:head-of-administer-signatory,' +
    '~tag:creation-date,' +
    '~tag:signatories,' +
    '~tag:company-signatories,' +
    '~tag:company-signatory-full-name,' +
    'tag:signatory-list,' +
    'tag:purchase-items,' +
    '~tag:procurement-date-of-conclusion,' +
    'tag:invoice-fine,' +
    'tag:invoice-fine-text,' +
    '~tag:receipt-date-year,' +
    '~tag:receipt-date-month,' +
    '~tag:receipt-date-day,' +
    '~tag:procurement-type,' +
    '~tag:sum-price,' +
    '~tag:sum-price-in-words,' +
    '~tag:exchange-field,' +
    '~tag:currency-exchange-field,' +
    '~tag:exchange-field-bellow,' +
    'tag:position-infos,' +
    '~tag:date,' +
    '~tag:month,' +
    '~tag:legal-basis,' +
    '~tag:guarantee-article,' +
    '~tag:procurement-document-number,' +
    '~tag:corrected-parent-date-of-conclusion,' +
    '~tag:corrected-parent-signed-document-number,' +
    '~tag:submission-author-info';
