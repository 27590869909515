"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissions = void 0;
exports.permissions = {
    'users.create': 'usersCreate',
    'users.read': 'usersRead',
    'users.update': 'usersUpdate',
    'users.delete': 'usersDelete',
    'users.emulate_user': 'usersEmulateUser',
    'users.show_module': 'usersShowModule',
    'users.attach_permission': 'userAttachPermission',
    'users.read_permission': 'userReadPermission',
    'users.delete_relations': 'usersDeleteRelations',
    'employees.create': 'employeesCreate',
    'employees.read': 'employeesRead',
    'employees.update': 'employeesUpdate',
    'employees.delete': 'employeesDelete',
    'companies.create': 'companiesCreate',
    'companies.read': 'companiesRead',
    'companies.update': 'companiesUpdate',
    'companies.delete': 'companiesDelete',
    'companies.show_module': 'companiesShowModule',
    'currencies.create': 'currenciesCreate',
    'currencies.read': 'currenciesRead',
    'currencies.update': 'currenciesUpdate',
    'currencies.delete': 'currenciesDelete',
    'currencies.show_module': 'currenciesShowModule',
    'contract_types.create': 'contractTypesCreate',
    'contract_types.read': 'contractTypesRead',
    'contract_types.update': 'contractTypesUpdate',
    'contract_types.delete': 'contractTypesDelete',
    'contract_types.show_module': 'contractTypesShowModule',
    'positions.create': 'positionsCreate',
    'positions.read': 'positionsRead',
    'positions.update': 'positionsUpdate',
    'positions.delete': 'positionsDelete',
    'structural_units.create': 'structuralUnitsCreate',
    'structural_units.read': 'structuralUnitsRead',
    'structural_units.update': 'structuralUnitsUpdate',
    'structural_units.delete': 'structuralUnitsDelete',
    'structural_units.show_module': 'structuralUnitsShowModule',
    'inventory_configurations.create': 'inventoryConfigurationsCreate',
    'inventory_configurations.read': 'inventoryConfigurationsRead',
    'inventory_configurations.update': 'inventoryConfigurationsUpdate',
    'inventory_configurations.delete': 'inventoryConfigurationsDelete',
    'inventory_configurations.show_module': 'inventoryConfigurationsShowModule',
    'inventory_items.create': 'inventoryItemsCreate',
    'inventory_items.read': 'inventoryItemsRead',
    'inventory_items.update': 'inventoryItemsUpdate',
    'inventory_items.delete': 'inventoryItemsDelete',
    'inventory_items.show_module': 'inventoryItemsShowModule',
    'inventory_specifications.create': 'inventorySpecificationsCreate',
    'inventory_specifications.read': 'inventorySpecificationsRead',
    'inventory_specifications.update': 'inventorySpecificationsUpdate',
    'inventory_specifications.delete': 'inventorySpecificationsDelete',
    'inventory_specifications.show_module': 'inventorySpecificationsShowModule',
    'inventory_categories.create': 'inventoryCategoriesCreate',
    'inventory_categories.read': 'inventoryCategoriesRead',
    'inventory_categories.update': 'inventoryCategoriesUpdate',
    'inventory_categories.delete': 'inventoryCategoriesDelete',
    'inventory_categories.show_module': 'inventoryCategoriesShowModule',
    'inventory_configurations.verify_item': 'inventoryConfigurationsVerifyItem',
    'budget_articles.create': 'budgetArticlesCreate',
    'budget_articles.read': 'budgetArticlesRead',
    'budget_articles.update': 'budgetArticlesUpdate',
    'budget_articles.delete': 'budgetArticlesDelete',
    'budget_articles.show_module': 'budgetArticlesShowModule',
    'funding_sources.create': 'fundingSourcesCreate',
    'funding_sources.read': 'fundingSourcesRead',
    'funding_sources.update': 'fundingSourcesUpdate',
    'funding_sources.delete': 'fundingSourcesDelete',
    'funding_sources.show_module': 'fundingSourcesShowModule',
    'budget_sources.create': 'budgetSourcesCreate',
    'budget_sources.read': 'budgetSourcesRead',
    'budget_sources.update': 'budgetSourcesUpdate',
    'budget_sources.delete': 'budgetSourcesDelete',
    'budget_sources.show_module': 'budgetSourcesShowModule',
    'projects.create': 'projectsCreate',
    'projects.read': 'projectsRead',
    'projects.update': 'projectsUpdate',
    'projects.delete': 'projectsDelete',
    'projects.show_module': 'projectsShowModule',
    'warranty_terms.create': 'warrantyTermsCreate',
    'warranty_terms.read': 'warrantyTermsRead',
    'warranty_terms.update': 'warrantyTermsUpdate',
    'warranty_terms.delete': 'warrantyTermsDelete',
    'warranty_terms.show_module': 'warrantyTermsShowModule',
    'countries.create': 'countriesCreate',
    'countries.read': 'countriesRead',
    'countries.update': 'countriesUpdate',
    'countries.delete': 'countriesDelete',
    'countries.show_module': 'countriesShowModule',
    'purchase_submissions.create': 'purchaseSubmissionsCreate',
    'purchase_submissions.read': 'purchaseSubmissionsRead',
    'purchase_submissions.update': 'purchaseSubmissionsUpdate',
    'purchase_submissions.delete': 'purchaseSubmissionsDelete',
    'purchase_submissions.show_module': 'purchaseSubmissionsShowModule',
    'purchase_conditional_submissions.create': 'purchaseConditionalSubmissionsCreate',
    'purchase_conditional_submissions.read': 'purchaseConditionalSubmissionsRead',
    'purchase_conditional_submissions.update': 'purchaseConditionalSubmissionsUpdate',
    'purchase_conditional_submissions.delete': 'purchaseConditionalSubmissionsDelete',
    'purchase_conditional_submissions.show_module': 'purchaseConditionalSubmissionsShowModule',
    'cpv_codes.create': 'cpvCodesCreate',
    'cpv_codes.read': 'cpvCodesRead',
    'cpv_codes.update': 'cpvCodesUpdate',
    'cpv_codes.delete': 'cpvCodesDelete',
    'cpv_codes.show_module': 'cpvCodesShowModule',
    'dimension_units.create': 'dimensionUnitsCreate',
    'dimension_units.read': 'dimensionUnitsRead',
    'dimension_units.show_module': 'dimensionUnitsShowModule',
    'dimension_units.update': 'dimensionUnitsUpdate',
    'dimension_units.delete': 'dimensionUnitsDelete',
    'media_files.create': 'mediaFilesCreate',
    'media_files.read': 'mediaFilesRead',
    'media_files.update': 'mediaFilesUpdate',
    'media_files.delete': 'mediaFilesDelete',
    'media_files.show_module': 'mediaFilesShowModule',
    'purchase_submission_verifies.create': 'purchaseSubmissionVerifiesCreate',
    'purchase_submission_verifies.read': 'purchaseSubmissionVerifiesRead',
    'purchase_submission_verifies.update': 'purchaseSubmissionVerifiesUpdate',
    'purchase_submission_verifies.delete': 'purchaseSubmissionVerifiesDelete',
    'purchase_submission_verifies.show_module': 'purchaseSubmissionVerifiesShowModule',
    'purchase_submission_verifies.confirmation_request': 'purchaseSubmissionVerifiesConfirmationRequest',
    'purchase_submission_verifies.budgeting_dep_rights': 'purchaseSubmissionVerifiesBudgetingDepRights',
    'purchase_submission_verifies.purchases_dep_rights': 'purchaseSubmissionVerifiesPurchasesDepRights',
    'purchase_submission_item.create': 'purchaseSubmissionItemCreate',
    'purchase_submission_item.read': 'purchaseSubmissionItemRead',
    'purchase_submission_item.update': 'purchaseSubmissionItemUpdate',
    'purchase_submission_item.delete': 'purchaseSubmissionItemDelete',
    'purchase_submission_item.show_module': 'purchaseSubmissionItemShowModule',
    'purchase_verify_item.create': 'purchaseVerifyItemCreate',
    'purchase_verify_item.read': 'purchaseVerifyItemRead',
    'purchase_verify_item.update': 'purchaseVerifyItemUpdate',
    'purchase_verify_item.delete': 'purchaseVerifyItemDelete',
    'purchase_verify_item.show_module': 'purchaseVerifyItemShowModule',
    'procurements.create': 'procurementsCreate',
    'procurements.read': 'procurementsRead',
    'procurements.report': 'procurementsReport',
    'procurements.update': 'procurementsUpdate',
    'procurements.delete': 'procurementsDelete',
    'procurements.show_module': 'procurementsShowModule',
    'procurements.create_correction_for_any_user': 'procurementsCreateCorrectionForAnyUser',
    'purchase_procurement_type.create': 'purchaseProcurementTypeCreate',
    'purchase_procurement_type.read': 'purchaseProcurementTypeRead',
    'purchase_procurement_type.update': 'purchaseProcurementTypeUpdate',
    'purchase_procurement_type.delete': 'purchaseProcurementTypeDelete',
    'purchase_procurement_type.show_module': 'purchaseProcurementTypeShowModule',
    'purchase_categories.create': 'purchaseCategoriesCreate',
    'purchase_categories.read': 'purchaseCategoriesRead',
    'purchase_categories.update': 'purchaseCategoriesUpdate',
    'purchase_categories.delete': 'purchaseCategoriesDelete',
    'donors.create': 'donorsCreate',
    'donors.read': 'donorsRead',
    'donors.update': 'donorsUpdate',
    'donors.delete': 'donorsDelete',
    'donors.show_module': 'donorsShowModule',
    'contracts.create': 'contractsCreate',
    'contracts.read': 'contractsRead',
    'contracts.update': 'contractsUpdate',
    'contracts.delete': 'contractsDelete',
    'contracts.show_module': 'contractsShowModule',
    'warehouse.create': 'warehouseCreate',
    'warehouse.read': 'warehouseRead',
    'warehouse.update': 'warehouseUpdate',
    'warehouse.delete': 'warehouseDelete',
    'warehouse.show_module': 'warehouseShowModule',
    'invoices.create': 'invoicesCreate',
    'invoices.read': 'invoicesRead',
    'invoices.update': 'invoicesUpdate',
    'invoices.delete': 'invoicesDelete',
    'invoices.show_module': 'invoicesShowModule',
    'invoices.warehouse_can_edit': 'invoicesWarehouseCanEdit',
    'invoices.update_fine': 'invoicesUpdateFine',
    'purchase_conditional_submissions.attach_request_registration_date': 'attachRequestRegistrationDate',
    'purchase_conditional_submissions.attach_edoc_number': 'attachEdocNumber',
    'user_unit_permissions.budgeting_dep': 'userUnitPermissionsBudgetingDep',
    'user_unit_permissions.hr_dep': 'userUnitPermissionsHrDep',
    'user_unit_permissions.purchase_dep': 'userUnitPermissionsPurchaseDep',
    'assign_submissions.read': 'assignSubmissionsRead',
    'assign_submissions.create': 'assignSubmissionsCreate',
    'assign_submissions.update': 'assignSubmissionsUpdate',
    'assign_submissions.delete': 'assignSubmissionsDelete',
    'assign_submissions.attach_edoc_data': 'attachEdocData',
    'roles.create': 'rolesCreate',
    'roles.read': 'rolesRead',
    'roles.update': 'rolesUpdate',
    'roles.attach_role': 'rolesAttachRole',
    'projects.modify_projects_users_attachments': 'projectsModifyProjectsUsersAttachments',
    'districts.read': 'districtsRead',
    'districts.create': 'districtsCreate',
    'districts.update': 'districtsUpdate',
    'districts.delete': 'districtsDelete',
    'locations.read': 'locationsRead',
    'locations.create': 'locationsCreate',
    'locations.update': 'locationsUpdate',
    'locations.delete': 'locationsDelete',
    'regions.create': 'regionsCreate',
    'regions.read': 'regionsRead',
    'regions.update': 'regionsUpdate',
    'regions.delete': 'regionsDelete',
    'assign_submissions.show_module': 'assignSubmissionsShowModule',
    'assign_submission_verifies.create': 'assignSubmissionVerifiesCreate',
    'assign_submission_verifies.read': 'assignSubmissionVerifiesRead',
    'assign_submission_verifies.update': 'assignSubmissionVerifiesUpdate',
    'assign_submission_verifies.delete': 'assignSubmissionVerifiesDelete',
    'regions.show_module': 'regionsShowModule',
    'locations.show_module': 'locationsShowModule',
    'districts.show_module': 'districtsShowModule',
    'assign_submission_verifies.show_module': 'assignSubmissionVerifiesShowModule',
    'work_types.show_module': 'workTypesShowModule',
    'work_types.read': 'workTypesRead',
    'work_types.create.': 'workTypesCreate',
    'work_types.update': 'workTypesUpdate',
    'work_types.delete': 'workTypesDelete',
    'administrative_orders.create': 'administrativeOrdersCreate',
    'administrative_orders.read': 'administrativeOrdersRead',
    'administrative_orders.update': 'administrativeOrdersUpdate',
    'administrative_orders.delete': 'administrativeOrdersDelete',
    'administrative_orders.show_module': 'administrativeOrdersShowModule',
    'position_sub_type.create': 'positionSubTypeCreate',
    'position_sub_type.read': 'positionSubTypeRead',
    'position_sub_type.update': 'positionSubTypeUpdate',
    'position_sub_type.delete': 'positionSubTypeDelete',
    'position_sub_type.show_module': 'positionSubTypeShowModule',
    'position_types.create': 'positionTypesCreate',
    'position_types.read': 'positionTypesRead',
    'position_types.update': 'positionTypesUpdate',
    'position_types.delete': 'positionTypesDelete',
    'position_types.show_module': 'positionTypesShowModule',
    'position_types_config.create': 'positionTypesConfigCreate',
    'position_types_config.read': 'positionTypesConfigRead',
    'position_types_config.update': 'positionTypesConfigUpdate',
    'position_types_config.delete': 'positionTypesConfigDelete',
    'position_types_config.show_module': 'positionTypesConfigShowModule',
    'vacations.create': 'vacationsCreate',
    'vacations.read': 'vacationsRead',
    'vacations.update': 'vacationsUpdate',
    'vacations.delete': 'vacationsDelete',
    'vacations.show_module': 'vacationsShowModule',
    'holidays.create': 'holidaysCreate',
    'holidays.show_module': 'holidaysShowModule',
    'holidays.update': 'holidaysUpdate',
    'holidays.delete': 'holidaysDelete',
    'legal_basis.create': 'legalBasicsCreate',
    'legal_basis.read': 'legalBasicsRead',
    'legal_basis.update': 'legalBasicsUpdate',
    'legal_basis.delete': 'legalBasicsDelete',
    'legal_basis.show_module': 'legalBasicsShowModule',
    'assign_contract_types.create': 'HRContractsCreate',
    'assign_contract_types.read': 'HRContractsRead',
    'assign_contract_types.update': 'HRContractsUpdate',
    'assign_contract_types.delete': 'HRContractsDelete',
    'assign_contract_types.show_module': 'HRContractsShowModule',
    'assign_contracts.create': 'assignContractsCreate',
    'assign_contracts.read': 'assignContractsRead',
    'assign_contracts.update': 'assignContractsUpdate',
    'assign_contracts.delete': 'assignContractsDelete',
    'assign_contracts.show_module': 'assignContractsShowModule',
    'banks.create': 'banksCreate',
    'banks.read': 'banksRead',
    'banks.update': 'banksUpdate',
    'banks.delete': 'banksDelete',
    'banks.show_module': 'banksShowModule',
    'guarantee_articles.create': 'guaranteeArticlesCreate',
    'guarantee_articles.read': 'guaranteeArticlesRead',
    'guarantee_articles.update': 'guaranteeArticlesUpdate',
    'guarantee_articles.delete': 'guaranteeArticlesDelete',
    'guarantee_articles.show_module': 'guaranteeArticlesShowModule',
    'work_directions.show_module': 'workDirectionsShowModule',
    'work_directions.read': 'workDirectionsRead',
    'work_directions.create': 'workDirectionsCreate',
    'work_directions.update': 'workDirectionsUpdate',
    'work_directions.delete': 'workDirectionsDelete',
    'administrative_buildings.show_module': 'administrativeBuildingsShowModule',
    'administrative_buildings.read': 'administrativeBuildingsRead',
    'administrative_buildings.create': 'administrativeBuildingsCreate',
    'administrative_buildings.update': 'administrativeBuildingsUpdate',
    'administrative_buildings.delete': 'administrativeBuildingsDelete',
    'administrative_building_rooms.show_module': 'roomsShowModule',
    'administrative_building_rooms.create': 'roomsCreate',
    'administrative_building_rooms.read': 'roomsRead',
    'administrative_building_rooms.delete': 'roomsDelete',
    'administrative_building_rooms.update': 'roomsUpdate',
    'user_contract_files.see_all_items': 'userContractFilesSeeAllItems',
    'users.delete_signatory_file': 'usersDeleteSignatoryFile',
    'user_contract_files.accounting_record_items': 'userContractFilesAccountingRecordItems',
    'news.manage': 'newsManage',
    'user_contract_files.remove_files': 'userContractFilesRemove',
    'emails.read': 'emailsRead',
    'emails.show_module': 'emailsShowModule',
    'user_auth_logs.show_module': 'userAuthLogsShowModule',
    'user_auth_logs.read_all': 'userAuthLogsReadAll'
};
