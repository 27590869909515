"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.guaranteeArticleReducer = exports.useGuaranteeArticleReducer = exports.initialGuaranteeArticleState = void 0;
const react_1 = require("react");
const initialGuaranteeArticleValue = { title: '' };
exports.initialGuaranteeArticleState = {
    data: initialGuaranteeArticleValue,
    errors: null,
    status: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useGuaranteeArticleReducer = () => {
    return (0, react_1.useReducer)(exports.guaranteeArticleReducer, exports.initialGuaranteeArticleState);
};
exports.useGuaranteeArticleReducer = useGuaranteeArticleReducer;
const guaranteeArticleReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_GUARANTEE_ARTICLE_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_GUARANTEE_ARTICLE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_GUARANTEE_ARTICLE_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_GUARANTEE_ARTICLE_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isCreating: false,
                status: action.payload.status,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_GUARANTEE_ARTICLE_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_GUARANTEE_ARTICLE_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdating: false,
                status: action.payload.status,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.guaranteeArticleReducer = guaranteeArticleReducer;
