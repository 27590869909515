"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useGuaranteeArticleSidebar = () => {
    const [openedGuaranteeArticleSidebar, setOpenedGuaranteeArticleSidebar] = (0, react_1.useState)(false);
    const closeGuaranteeArticleSideBar = (event) => {
        const isGuaranteeArticle = document.querySelector('#guaranteeArticle-menu')?.contains(event.target);
        const isGuaranteeArticleSidebarAlert = document.querySelector('#guaranteeArticleAlert')?.contains(event.target);
        if (isGuaranteeArticle || isGuaranteeArticleSidebarAlert)
            return;
        window.removeEventListener('click', closeGuaranteeArticleSideBar, true);
        setOpenedGuaranteeArticleSidebar(false);
    };
    const setGuaranteeArticleSideBar = (isOpen) => {
        setOpenedGuaranteeArticleSidebar(() => {
            if (isOpen)
                window.addEventListener('click', closeGuaranteeArticleSideBar, true);
            return isOpen;
        });
    };
    (0, react_1.useEffect)(() => {
        const outsideClick = (e) => {
            if (!e.target.closest('#guaranteeArticle-menu')
                && !e.target.closest('#guaranteeArticle-menu'))
                setGuaranteeArticleSideBar(false);
        };
        document.body.addEventListener('click', outsideClick, true);
        return () => {
            document.body.removeEventListener('click', outsideClick);
        };
    }, []);
    return {
        openedGuaranteeArticleSidebar,
        setOpenedGuaranteeArticleSidebar,
        setGuaranteeArticleSideBar
    };
};
exports.default = useGuaranteeArticleSidebar;
