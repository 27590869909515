"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employeeContractAndAnnexReducer = exports.useContractAndAnnexReducer = exports.initialContractAndAnnex = void 0;
const types_1 = require("api/references/employees/types");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const parsers_1 = require("api/files/parsers");
const initialContractFormValues = {
    id: null,
    contract: parsers_1.initialFile,
    hash: '',
    annex: parsers_1.initialFile,
    status: types_1.ContractAndAnnexStatuses.DRAFT,
    commentQuantity: 0,
    creatorAccountId: null,
    contractFileUid: null,
    corrections: [],
    startDate: null,
    endDate: null,
    isActive: { name: '', color: '' },
    academicDegree: null,
    semester: null,
    activeCorrection: {
        id: null,
        creatorAccountId: null,
        contract: parsers_1.initialFile,
        annex: parsers_1.initialFile,
        status: types_1.ContractAndAnnexStatuses.DRAFT,
        hash: '',
        commentQuantity: 0,
        contractFileUid: null,
        endDate: null,
        startDate: null,
        isActive: { name: '', color: '' },
        academicDegree: null,
        semester: null
    }
};
exports.initialContractAndAnnex = {
    data: [initialContractFormValues],
    loadingIds: [],
    errors: null,
    status: null,
    isFetching: true,
    isSending: false,
    isDeleting: false,
    isCorrecting: false,
    isAddingNew: false,
    isCreating: false
};
const useContractAndAnnexReducer = () => {
    return (0, react_1.useReducer)(exports.employeeContractAndAnnexReducer, exports.initialContractAndAnnex);
};
exports.useContractAndAnnexReducer = useContractAndAnnexReducer;
const employeeContractAndAnnexReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CONTRACT_FILES_REQUEST':
            return {
                ...state,
                errors: null
            };
        case 'DONE_CONTRACT_FILES_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false
            };
        case 'SEND_CREATE_UPDATE_CONTRACT_REQUEST':
            return {
                ...state,
                errors: null,
                loadingIds: [...state.loadingIds, action.payload.id],
                isCreating: true
            };
        case 'DONE_CREATE_UPDATE_CONTRACT_REQUEST':
            return {
                ...state,
                data: action.payload.response.data
                    ? state.data.map(contract => {
                        if (contract.id === action.payload.id) {
                            return action.payload.response.data;
                        }
                        return contract;
                    })
                    : state.data,
                loadingIds: state.loadingIds.filter(el => el !== action.payload.id),
                errors: action.payload.response.errors,
                status: action.payload.response.status,
                isCreating: false
            };
        case 'SEND_SENDING_CONTRACT_REQUEST':
            return {
                ...state,
                errors: null,
                isSending: true
            };
        case 'DONE_SENDING_CONTRACT_REQUEST':
            return {
                ...state,
                data: action.payload.response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS
                    ? state.data.map(contract => contract.id === action.payload.originalContractId
                        ? {
                            ...contract,
                            ...(action.payload.correctionId
                                ? { activeCorrection: { ...contract.activeCorrection, status: types_1.ContractAndAnnexStatuses.SENT } }
                                : { status: types_1.ContractAndAnnexStatuses.SENT })
                        }
                        : contract)
                    : state.data,
                errors: action.payload.response.errors || null,
                status: action.payload.response.status,
                isSending: false
            };
        case 'SEND_CREATE_UPDATE_CORRECTION_REQUEST':
            return {
                ...state,
                errors: null,
                isCorrecting: true,
                loadingIds: [...state.loadingIds, action.payload.id]
            };
        case 'DONE_CREATE_UPDATE_CORRECTION_REQUEST':
            return {
                ...state,
                data: action.payload.response.data
                    ? state.data.map(contract => {
                        if (contract.id !== action.payload.originalContractId)
                            return contract;
                        return {
                            ...contract,
                            isActive: action.payload.response.data?.isActive.name.length !== 0 ? action.payload.response.data?.isActive : contract.isActive,
                            activeCorrection: {
                                ...contract.activeCorrection,
                                ...action.payload.response.data
                            }
                        };
                    }) : state.data,
                loadingIds: state.loadingIds.filter(el => el !== action.payload.id),
                errors: action.payload.response.errors,
                status: action.payload.response.status,
                isCorrecting: false
            };
        case 'SEND_CREATE_NEW_CONTRACT':
            return {
                ...state,
                isAddingNew: true
            };
        case 'DONE_CREATE_NEW_CONTRACT':
            return {
                ...state,
                data: [{
                        ...initialContractFormValues,
                        startDate: action.payload.semester?.startDate || null,
                        endDate: action.payload.semester?.endDate || null,
                        academicDegree: action.payload.academicDegree || null,
                        semester: action.payload.semester || null
                    }, ...state.data
                ],
                isAddingNew: false
            };
        case 'DELETE_UNSAVED_CONTRACTS': {
            return {
                ...state,
                data: state.data.filter(el => el.id !== null)
            };
        }
        default:
            return state;
    }
};
exports.employeeContractAndAnnexReducer = employeeContractAndAnnexReducer;
