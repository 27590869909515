"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformProcurementTemplateParams = exports.getItemsParamsFromUrl = exports.transformProcurementItemsParams = exports.transformProcurementItemsListParams = exports.transformUrlToFilterParams = exports.transformFilterParamsToUrl = exports.transformVerifiedItemsFilterParams = exports.transformProcurementParamsToFilterParams = exports.ProcurementContentTypes = exports.transformSignatureDocument = exports.transformSignatureParams = exports.transformProcurementParams = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_1 = require("api/documents/purchases/procurements/types");
const types_2 = require("api/documents/purchases/procurementCorrections/types");
const helper_1 = require("src/modules/common/helper");
const queryString_1 = require("core/helpers/queryString");
const transformProcurementParams = (params, create) => {
    const procurementFile = params.procurementFile && {
        uid: params.procurementFile.id,
        type: params.templateType,
        is_other: params.templateType === types_1.TemplateTypes.CONSOLIDATED ? false : params.isOther
    };
    const files = params.files.map(el => ({ uid: el.id, type: types_1.TemplateTypes.ADDITIONAL_FILES, is_other: false }));
    const attachedFiles = params.attachedFiles.map(el => ({ uid: el.id, type: types_1.TemplateTypes.SIGNATURE_DOCUMENT, is_other: false }));
    const attachedSignedFiles = params.attachedSignedFiles.map(el => ({ uid: el.id, type: types_1.TemplateTypes.SIGNED, is_other: false }));
    const fileUids = [...files, ...attachedFiles, ...attachedSignedFiles];
    if (procurementFile)
        fileUids.push(procurementFile);
    const parameters = {
        signedDocumentNumber: params.status === types_1.ProcurementStatuses.SIGN_PROGRESS ? params.signedDocumentNumber : undefined,
        dateOfConclusion: params.status === 3 ? params.dateOfConclusion && (0, helper_1.nullableTransformParamsDate)(params.dateOfConclusion) : undefined,
        deliveryDate: (0, helper_1.transformParamsDate)(params.deliveryDate),
        contractValidityDate: (0, helper_1.transformParamsDate)(params.contractValidityDate),
        advanceGuaranteeValidityPeriod: (0, helper_1.nullableTransformParamsDate)(params.advanceGuaranteeValidityPeriod),
        goodsGuaranteeValidityPeriod: (0, helper_1.nullableTransformParamsDate)(params.goodsGuaranteeValidityPeriod),
        contractGuaranteeValidityPeriod: (0, helper_1.nullableTransformParamsDate)(params.contractGuaranteeValidityPeriod),
        typeId: params.type.id,
        companyId: params.company.id,
        companyAddressId: params.companyAddress.id,
        companyBankAccountId: params.companyBankAccount.id,
        companySignatoryId: params.companySignatory.id,
        templateType: params.templateType,
        legalBasisId: params.legalBasis ? params.legalBasis.id : null,
        guaranteeArticleIds: params.guaranteeArticles ? params.guaranteeArticles?.map(el => el.id) : null,
        template: params.content,
        fileUids
    };
    if (create)
        delete parameters.template; // ვშილთ რადგან create-ის დროს ბექს არ უნდა გავუგზავნოთ
    return parameters;
};
exports.transformProcurementParams = transformProcurementParams;
const transformSignatureParams = (values) => {
    return {
        fileType: types_1.TemplateTypes.SIGNATURE_DOCUMENT,
        dateOfConclusion: (0, helper_1.nullableTransformParamsDate)(values.dateOfConclusion),
        signedDocumentNumber: values.signedDocumentNumber,
        fileUid: values.attachedFiles[0].id
    };
};
exports.transformSignatureParams = transformSignatureParams;
const transformSignatureDocument = (values) => {
    return {
        fileType: types_1.TemplateTypes.SIGNED,
        fileUid: values.attachedSignedFiles[0].id
    };
};
exports.transformSignatureDocument = transformSignatureDocument;
exports.ProcurementContentTypes = {
    1: 'electronicTender',
    2: 'simplifiedProcurement'
};
const transformProcurementParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        status: (params.status && Number(params.status.id)) || undefined,
        templateType: (params.templateType && Number(params.templateType.id)) || undefined,
        contractType: (params.contractType && Number(params.contractType.id)) || undefined,
        isOnlyFile: params.isOnlyFile,
        createdBy: params.createdBy?.id || params.onlyMine,
        budgetArticleId: params.budgetArticleId?.id,
        keyword: params.keyword,
        projectId: params.project?.id,
        approverId: params.approver?.id,
        companyIds: params.company?.map(el => el.id),
        approverStatus: params.approverStatus?.id,
        isForceMajeure: params.isForceMajeure,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformProcurementParamsToFilterParams = transformProcurementParamsToFilterParams;
const transformVerifiedItemsFilterParams = (values) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: values.keyword,
        submissionIds: values.approvedSubmissions?.map(el => el.id),
        submissionCreatorId: values.submissionCreator?.id,
        cpvCodeIds: values.cpvCodes?.map(el => el.id),
        procurementCorrectionId: values.procurementCorrection,
        page: values.page,
        perPage: values.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformVerifiedItemsFilterParams = transformVerifiedItemsFilterParams;
const transformFilterParamsToUrl = (values) => {
    const { keyword, status, budgetArticleId, createdBy, project, approver, company, approverStatus, page, perPage, templateType, contractType, isOnlyFile, isForceMajeure } = values;
    return {
        keyword: keyword,
        isOnlyFile: isOnlyFile ? 1 : undefined,
        statusId: status && status.id,
        statusLabel: status && status.label,
        templateTypeId: templateType && templateType.id,
        templateTypeLabel: templateType && templateType.label,
        contractTypeId: contractType && contractType.id,
        contractTypeLabel: contractType && contractType.label,
        createdById: createdBy && createdBy.id,
        createdByLabel: createdBy && createdBy.label,
        projectId: project && project.id,
        projectLabel: project && project.label,
        approverId: approver && approver.id,
        approverLabel: approver && approver.label,
        companyIds: company && company.map(el => el.id),
        companyLabels: company && company.map(el => el.label),
        approverStatusId: approverStatus && approverStatus.id,
        approverStatusLabel: approverStatus && approverStatus.label,
        budgetArticleId: budgetArticleId && budgetArticleId.id,
        budgetArticleLabel: budgetArticleId && budgetArticleId.label,
        isForceMajeure: isForceMajeure ? 1 : undefined,
        page,
        perPage
    };
};
exports.transformFilterParamsToUrl = transformFilterParamsToUrl;
const transformUrlToFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        isOnlyFile: values.isOnlyFile ? Number(values.isOnlyFile) === apiGlobalTypes_1.YesNoStatus.YES : undefined,
        contractType: values.contractTypeLabel && values.contractTypeId ? { id: values.contractTypeId, label: values.contractTypeLabel } : undefined,
        templateType: values.templateTypeLabel && values.templateTypeId ? { id: values.templateTypeId, label: values.templateTypeLabel } : undefined,
        status: values.statusLabel && values.statusId ? { id: values.statusId, label: values.statusLabel } : undefined,
        createdBy: values.createdById && values.createdByLabel ? { id: values.createdById, label: values.createdByLabel } : undefined,
        project: values.projectId && values.projectLabel ? { id: values.projectId, label: values.projectLabel } : undefined,
        approver: values.approverId && values.approverLabel ? { id: values.approverId, label: values.approverLabel } : undefined,
        approverStatus: values.approverStatusId && values.approverStatusLabel ? { id: values.approverStatusId, label: values.approverStatusLabel } : undefined,
        company: values.companyIds?.map((el, index) => ({ id: el, label: (values.companyLabels && values.companyLabels[index]) })),
        budgetArticleId: values.budgetArticleId && values.budgetArticleLabel ? { id: values.budgetArticleId, label: values.budgetArticleLabel } : undefined,
        isForceMajeure: values.isForceMajeure ? Number(values.isForceMajeure) === apiGlobalTypes_1.YesNoStatus.YES : undefined,
        page: values.page,
        perPage: values.perPage,
        correctionId: values.correctionId
    };
};
exports.transformUrlToFilterParams = transformUrlToFilterParams;
const transformProcurementItemsListParams = (values, reasons) => {
    return { items: values.map(el => transformProcurementItemListParams(el, reasons)) };
};
exports.transformProcurementItemsListParams = transformProcurementItemsListParams;
const transformProcurementItemListParams = (item, reasons) => {
    return {
        id: item.procurementItemId || null,
        verifyItemId: !item.procurementItemId ? item.id : undefined,
        budgetArticleId: item.budgetArticle?.id,
        price: item.price,
        quantity: reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.CHANGE_QUANTITY_OF_PURCHASE_SUBJECTS) ? item.quantity || 0 : undefined,
        projectId: reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.FUNDING_SOURCE) ? item.project.id : undefined,
        inventoryItemTitle: reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.SUBJECT_NAME) ? item.purchaseSubject : undefined,
        cpvCodeId: reasons?.some(reason => Number(reason.id) === types_2.ReasonTypes.CHANGE_CPV_CODE) ? item.cpvCode.id : undefined
    };
};
const transformProcurementItemsParams = (params) => {
    return {
        page: params.itemPage,
        perPage: params.itemPerPage,
        filters: params.itemsKeyword ? { keyword: params.itemsKeyword } : undefined
    };
};
exports.transformProcurementItemsParams = transformProcurementItemsParams;
const getItemsParamsFromUrl = (url) => {
    const params = (0, queryString_1.parse)(url);
    return {
        itemPage: params.itemPage,
        itemsKeyword: params.itemsKeyword,
        itemPerPage: params.itemPerPage
    };
};
exports.getItemsParamsFromUrl = getItemsParamsFromUrl;
const transformProcurementTemplateParams = (value) => {
    return {
        content: value.content
    };
};
exports.transformProcurementTemplateParams = transformProcurementTemplateParams;
